export default {
  seekersList: {
    header: {
      title: "Client List",
      counter: "Clients",
    },
    list: {
      displayName: "Organisation Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Project List",
      counter: "Project",
    },
  },
  investorList: {
    header: {
      title: "Investors List",
      counter: "Investors",
    },
  },
  matchList: {
    header: {
      title: "Investor Interests List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { title: "Investment Activity", subtitle: "Matched deals" },
      investor: { title: "Investors", subtitle: "Onboarding progress" },
      investee: { title: "Projects", subtitle: "Projects Progress" },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to projects",
      role: "Project",
    },
  },
  investorDetail: {
    potentialDeals: {
      title: "Water projects ready for funding",
      subtitle: "Projects",
      table: {
        companyName: "Project Name",
        country: "Country",
        sector: "Water and sanitation sub-sectors",
        leadEmail: "Contact Person email",
        action: "Action",
      },
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to clients",
      role: "Client",
    },
  },
};
